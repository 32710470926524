import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CustomEase from 'gsap/CustomEase';
import Flip from 'gsap/Flip';

import navbar from '../components/navbar';
import footer from '../components/footer';
import hover from '../components/hover';

gsap.registerPlugin(ScrollTrigger, CustomEase, Flip);

document.addEventListener('DOMContentLoaded', (event) => {
    navbar();
    footer();
    hover();
});
