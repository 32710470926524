import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { Flip } from 'gsap/all';

export default function navbar() {
    const body = document.querySelector('body');
    const navLinks = document.querySelectorAll('.navbar_link');
    const navLinksWrap = document.querySelector('.navbar_menu');
    const currentLink = document.querySelector('.navbar_link.w--current');
    const navContainer = document.querySelector('.navbar_container');
    const shape = document.querySelector('.navbar_link-pad');
    let mm = gsap.matchMedia();

    const navTl = gsap.timeline({
        ease: 'power4.in',
        scrollTrigger: {
            trigger: '.navbar_component',
            start: '+=100',
            toggleActions: 'play none none reverse',
            onEnter: () => {
                navContainer.classList.add('is-scrolled');
            },
            onLeaveBack: () => {
                navContainer.classList.remove('is-scrolled');
            },
        },
    });
    mm.add('(min-width: 992px)', () => {
        navTl
            .to(
                '.navbar_logo.is-rest',
                {
                    opacity: 0,
                    width: 0,
                    duration: 0.5,
                    ease: 'power4.out',
                },
                0
            )
            .to(
                '.navbar_container',
                {
                    margin: '0 2em',
                    backgroundColor: '#073742',
                    duration: 0.3,
                },
                0
            );
    });
    mm.add('(max-width: 991px)', () => {
        navTl
            .to(
                '.navbar_logo.is-rest',
                {
                    opacity: 0,
                    width: 0,
                    duration: 0.5,
                    ease: 'power4.out',
                },
                0
            )
            .to(
                '.navbar_container',
                {
                    backgroundColor: '#073742',
                    duration: 0.3,
                },
                0
            );
    });
    mm.add('(hover:hover)', () => {
        navLinks.forEach(function (link) {
            link.addEventListener('mouseenter', function () {
                const state = Flip.getState(shape, {
                    props: 'opacity',
                    simple: true,
                });
                shape.classList.add('is-active');

                this.appendChild(shape);

                Flip.from(state, {
                    absolute: true,
                    duration: 0.3,
                    ease: 'power2.out',
                });
            });
        });

        navLinksWrap.addEventListener('mouseleave', function () {
            const state = Flip.getState(shape, {
                props: 'opacity',
                simple: true,
            });
            shape.classList.remove('is-active');
            Flip.from(state, {
                absolute: true,
                duration: 0.3,
                ease: 'power2.out',
                scale: true,
            });
        });
    });
    // Stop body scroll when full menu is open

    function letBodyScroll(bool) {
        if (bool) {
            body.style.overflow = 'hidden';
        } else {
            body.style.overflow = 'auto';
        }
    }
    const targetNode = document.querySelector('.navbar_menu-button');
    const config = { attributes: true, childList: false, subtree: false };
    const callback = function (mutationsList, observer) {
        for (let i = 0; i < mutationsList.length; i++) {
            if (mutationsList[i].type === 'attributes') {
                const menuIsOpen =
                    mutationsList[i].target.classList.contains('w--open');
                letBodyScroll(menuIsOpen);
            }
        }
    };
    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
}
